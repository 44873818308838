import './App.css';
import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PublicPages from './pages/public-pages/public-pages';
import { PrivateRoute } from './shared/helpers/private-route';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/dashboard';
import Builder from './pages/builder/builder';
import XcubeSpinner from './shared/components/xcube-spinner/xcube-spinner';
import { Constants } from './Constants';

function App() {
  return (
    /*eslint react/jsx-filename-extension:0*/
    <div>
      {Constants.endPoint.includes('test')
        && (
        <div className="alert alert-danger position-absolute" role="alert">
          The app is using Test End Point
        </div>
        )}
      <Router>
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/campaigns" component={Dashboard} />
          <PrivateRoute path="/account" component={Dashboard} />
          <PrivateRoute path="/wizard" component={Dashboard} />
          <PrivateRoute path="/builder" component={Builder} />
          <Route path="/" component={PublicPages} />
        </Switch>
        <ToastContainer />
      </Router>
      <XcubeSpinner />
    </div>
  );
}

export default App;
