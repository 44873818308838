import axios from 'axios';
import { Constants } from '../../Constants';

export const getDataService = {
  getAllTemplates,
  getAllGames,
  getActiveCampaigns,
  getInactiveCampaigns,
  campaignGeneralPage,
  campaignStatusPage,
  campaignPrizesPage,
  campaignEmailPage,
  dashboardPage,
  accountGeneralPage,
  validateSetup,
  exportLeads,
  getCompanyAnalytics,
  getCampaignAnalytics,
  getClientCompanies,
  getAccountPlan,
  getActiveClientCampaigns,
  getLeadsAndPrizes,
  getCampaignLeadsAndPrizes,
  getTeamsPage
};

function getAllTemplates() {
  return axios.get(Constants.endPoint + Constants.TEMPLATES.getAll)
    .then((resp) => resp);
}

function getAllGames() {
  return axios.get(Constants.endPoint + Constants.GAMES.getAll)
    .then((resp) => resp);
}

function getActiveCampaigns(page) {
  return axios.get(Constants.endPoint + Constants.CAMPAIGNS.getActiveCampaigns + page)
    .then((resp) => resp);
}

function getInactiveCampaigns(page) {
  return axios.get(Constants.endPoint + Constants.CAMPAIGNS.getInactiveCampaigns + page)
    .then((resp) => resp);
}

function campaignStatusPage(data, campaignId) {
  return axios.get(`${Constants.endPoint}/dashboard/${campaignId}/status?start_date=${data.start_date}&end_date=${data.end_date}`)
    .then((resp) => resp);
}

function campaignGeneralPage(campaignId) {
  return axios.get(`${Constants.endPoint}/dashboard/${campaignId}/general`)
    .then((resp) => resp);
}

function campaignPrizesPage(campaignId) {
  return axios.get(`${Constants.endPoint}/dashboard/${campaignId}/prizes`)
    .then((resp) => resp);
}

function campaignEmailPage(campaignId) {
  return axios.get(`${Constants.endPoint}/dashboard/${campaignId}/emails`)
    .then((resp) => resp);
}

function dashboardPage() {
  return axios.get(`${Constants.endPoint}/dashboard`)
    .then((resp) => resp);
}

function accountGeneralPage() {
  return axios.get(`${Constants.endPoint + Constants.ACCOUNT.general}`)
    .then((resp) => resp);
}

function validateSetup(campaignId) {
  return axios.get(`${Constants.endPoint + Constants.PUBLISH.validate(campaignId)}`)
    .then((resp) => resp);
}

function exportLeads(campaignId) {
  return axios.get(`${Constants.endPoint + Constants.LEADS.exportLeads(campaignId)}`);
}

function getCompanyAnalytics(companyId) {
  return axios.get(`${Constants.endPoint + Constants.COMPANY.companyAnalytics(companyId)}`)
    .then(resp => {
      return resp;
    });
}

function getCampaignAnalytics(campaignId) {
  return axios.get(`${Constants.endPoint + Constants.CAMPAIGNS.campaignAnalytics(campaignId)}`)
    .then(resp => {
      return resp;
    });
}

function getClientCompanies() {
  return axios.get(`${Constants.endPoint + Constants.USER.clientCompanies}`)
    .then(resp => {
      return resp;
    });
}

function getAccountPlan() {
  return axios.get(`${Constants.endPoint + Constants.ACCOUNT.plan}`)
    .then(resp => {
      return resp;
    });
}

function getActiveClientCampaigns(id) {
  return axios.get(`${Constants.endPoint + Constants.COMPANY.activeClientCampaigns(id)}`)
    .then(resp => {
      return resp;
    });
}

function getLeadsAndPrizes(data) {
  return axios.post(`${Constants.endPoint + Constants.LEADS.leadsAndPrizes}`, data)
    .then(resp => {
      return resp;
    });
}

function getCampaignLeadsAndPrizes(data, campaign_id) {
  return axios.post(`${Constants.endPoint + Constants.CAMPAIGNS.leadsAndPrizes(campaign_id)}`, data)
    .then(resp => {
      return resp;
    });
}

function getTeamsPage() {
  return axios.get(`${Constants.endPoint + Constants.ACCOUNT.team}`)
    .then(resp => {
      return resp;
    });
}

