import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import logo from './assets/brame-logo.svg';
import avatar from './assets/avatar.svg';
import './dashboard-navbar.scss';
import { authenticationService } from '../../../../shared/services/authenticationService';


class DashboardNavbar extends Component {
  constructor() {
    super();
    this.state = {
      userData: '',
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((user) => {
      this.setState((prevState) => ({
        ...prevState,
        userData: user,
      }));
    });
  }

  logOut = () => {
    localStorage.removeItem('currentUser');
    window.location.reload();
    // let auth2 = window.gapi.auth2.getAuthInstance();
    // auth2.signOut().then(() => {
    //   auth2.disconnect();

    // });
  }

  render() {
    return (
      <div id="dashboard-navbar">
        <Navbar expand="md">
          <Container>
            <Navbar.Brand href="/">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
              {' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <NavLink activeClassName="active" className="nav-link" to="/dashboard">Dashboard</NavLink>
                <NavLink activeClassName="active" className="nav-link" to="/campaigns">Campaigns</NavLink>
                <Nav.Link href="mailto:info@brame.ch?subject=Brame Support">Support</Nav.Link>
                <Dropdown className="mt-1">
                <Dropdown.Toggle id="dropdown-basic">
                {`${this.state.userData.first_name} ${this.state.userData.last_name}`}
                </Dropdown.Toggle>
                <div className="avatar-link">
                    <img src={avatar} alt="" />
                    {/* <span>My account</span> */}
                  </div>
                <Dropdown.Menu>
                  <Dropdown.Item href="/account/general">Account</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.logOut()}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default DashboardNavbar;
