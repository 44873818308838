import React, {Component} from 'react';
import "./xcube-analytics-game-info.scss";
import Col from 'react-bootstrap/Col';


class XcubeAnalyticsGameInfo extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="campaign-leads">
                <div className="card">
                    <div className="card-title mt-3 ml-3">
                        {this.props.name}
                    </div>
                    <table className="no-gutters card-body">
                        <tbody>
                            <tr>
                                <td className="pl-3 pb-2">
                                    {this.props.data?.length > 0 ? this.props.data.slice(0).reverse().map((answer, i) => {
                                        if(answer.questions){
                                            if(answer.questions.length > 27){
                                                return (
                                                    <p key={i} className="card-text">{answer.questions.substring(0, 27) + '...'}</p>
                                                )
                                            } else{
                                                return (
                                                    <p key={i} className="card-text">{answer.questions}</p>
                                                )
                                            }
                                        } else if(answer.name){
                                            if(answer.name.length > 27){
                                                return (
                                                    <p key={i} className="card-text">{answer.name.substring(0, 27) + '...'}</p>
                                                )
                                            } else{
                                                return (
                                                    <p key={i} className="card-text">{answer.name}</p>
                                                )
                                            }
                                        } else{
                                            return (
                                              <p key={i} className="card-text">Prize {i + 1}:</p>
                                            )
                                        }
                                    }) : <p className="card-text">No data to show yet</p> }
                                </td>
                                <td className="text-right pr-3 pb-2">
                                    {this.props.data?.length > 0 ? this.props.data.slice(0).reverse().map((answer, i) => {
                                        // if(i <= 2) {
                                        return (
                                          <p key={i} className="card-text"><strong>{ answer.occurrences } {answer.questions && "people"}</strong></p>
                                        )
                                        // }
                                    }) : null}
                                </td>
                                <td className="text-right pr-3 pb-2">
                                    {this.props.data?.length > 0 ? this.props.data.slice(0).reverse().map((answer, i) => {
                                        // if(i <= 2) {
                                        return (
                                          <p key={i} className="card-text"><strong>{ answer.percentage + '%' }</strong></p>
                                        )
                                        // }
                                    }) : null}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsGameInfo;
