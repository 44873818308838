import React, { Component} from 'react';
import {Row, Col, Table} from 'react-bootstrap'
import './xcube-analytics-best-time-details.scss'

class XcubeAnalyticsBestTimeDetails extends Component {
  renderTableRows() {
      let rows = [];
      if(this.props.data?.game_analytics.length > 0) {
        this.props.data.game_analytics.slice(0,10).map((player) => {
          rows.push(
            <tr key={player.name}>
              <td><span className='bullet' style={{backgroundColor: `${this.getRandomColor()}`}}></span> {player.name}</td>
              {player.hasOwnProperty("occurrences") &&
                <td style={{textAlign: 'center'}}>{player.occurrences}</td>
              }
              {player.hasOwnProperty("value") &&
                <td style={{textAlign: 'center'}}>{player.value}%</td>
              }
              {player.hasOwnProperty("percentage") &&
                <td style={{textAlign: 'center'}}>{player.percentage}%</td>
              }
            </tr>
          )
        })
      }
      else {
        if(this.props.data.leads[0]?.time) {
          this.props.data.leads.slice(0,10).map(lead => rows.push(<tr key={lead.email}>
              <td><span className='bullet' style={{backgroundColor: `${this.getRandomColor()}`}}></span> {lead.name}</td>
              <td style={{textAlign: 'center'}}>{lead.time.length < 2 ? `0${lead.time.slice(-2)}` : lead.time}</td>
            </tr>)
          );
        }
        else {
          return null
        }
      }
      return rows
    };


  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {

    return (
      <Row className='bestTimeDetails align-items-center pt-3'>
        <Col md={12} lg={12} className="text-center">
          {this.props.data.leads.length > 0
            ?
            <Table className='playersTime text-left' hover>
              <thead style={{borderBottom: 'none'}} >
              <tr>
                {this.props.data.game_analytics.length > 0 ? <th>Prizes</th> : this.props.data.leads[0]?.time && <th>Players</th> }
                {this.props.data.game_analytics.length > 0 ? <th>Occurrences</th> : this.props.data.leads[0]?.time && <th>Time</th> }
                {this.props.data.game_analytics.length > 0 &&
                  this.props.data.game_analytics[0].percentage && <th>Percentage</th>
                }
              </tr>
              </thead>
              <tbody>
              {this.renderTableRows()}
              </tbody>
            </Table>
            : <p>No info available</p>
          }
        </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsBestTimeDetails;
