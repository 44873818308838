import React, {Component} from 'react';
import "./campaign-status.scss";
import Row from "react-bootstrap/Row";
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import {getDataService} from "../../../../../../shared/services/getDataService";
import {dataHelper} from "../../../../../../shared/helpers/data-helper";
import XcubeShareModal
    from '../../../../../../shared/components/xcube-share-modal/xcube-share-modal';
import { setDataService } from '../../../../../../shared/services/setDataService';
import { Constants } from '../../../../../../Constants';
import { ClipLoader } from 'react-spinners';
import XcubeAnalyticsConversions
    from '../../../../../../shared/components/xcube-analytics-conversions/xcube-analytics-conversions';
import XcubeAnalyticsVisitors
    from '../../../../../../shared/components/xcube-analytics-visitors/xcube-analytics-visitors';
import XcubeAnalyticsDevices
    from '../../../../../../shared/components/xcube-analytics-devices/xcube-analytics-devices';
import XcubeAnalyticsVisitorsPerCountry
    from '../../../../../../shared/components/xcube-analytics-visitors-per-country/xcube-analytics-visitors-per-country';
import XcubeAnalyticsEngagement
    from '../../../../../../shared/components/xcube-analytics-engagement/xcube-analytics-engagement';
import XcubeDatePicker
    from '../../../../../../shared/components/xcube-date-picker/xcube-date-picker';
import Col from 'react-bootstrap/Col';
import * as moment from 'moment';
import { googleAnalyticsService } from '../../../../../../shared/services/googleAnalyticsService';
import DataTable from "react-data-table-component";
import XcubeAnalyticsGameInfo from '../../../../../../shared/components/xcube-analytics-game-info/xcube-analytics-game-info';
import XcubeAnalyticsBestTime from '../../../../../../shared/components/xcube-analytics-best-time/xcube-analytics-best-time';
import XcubeSelect from "../../../../../../shared/components/xcube-select/xcube-select";
import XcubeAnalyticsDevicesDetails
    from "../../../../../../shared/components/xcube-analytics-devices-details/xcube-analytics-devices-details";
import XcubeAnalyticsConversionDetails
    from "../../../../../../shared/components/xcube-analytics-conversion-details/xcube-analytics-conversion-details";
import XcubeAnalyticsVisitorsDetails
    from "../../../../../../shared/components/xcube-analytics-visitors-details/xcube-analytics-visitors-details";
import XcubeAnalyticsDetailsVisitorsPerCountry
    from "../../../../../../shared/components/xcube-analytics-details-visitors-per-country/xcube-analytics-details-visitors-per-country";
import XcubeAnalyticsDetailsEngagement
    from "../../../../../../shared/components/xcube-analytics-details-engagement/xcube-analytics-details-engagement";
import XcubeAnalyticsLeads
    from '../../../../../../shared/components/xcube-analytics-leads/xcube-analytics-leads';
import XcubeAnalyticsDetailsLeads
    from "../../../../../../shared/components/xcube-analytics-details-leads/xcube-analytics-details-leads";
import XcubeAnalyticsBestTimeDetails
    from "../../../../../../shared/components/xcube-analytics-best-time-details/xcube-analytics-best-time-details";
import orderBy from 'lodash/orderBy';
import XcubeAnalyticsQuestionDetails
    from '../../../../../../shared/components/xcube-analytics-question-details/xcube-analytics-question-details';


class CampaignStatus extends Component {
    campaignId;
    campaignData;
    editUrl;
    order = 1;
    constructor(props){
        super(props);
        this.myRef = React.createRef();

        this.state = {
            campaignData:{},
            analyticsConf: {
                startDate: '2020-04-01',
                endDate: moment().format('YYYY-MM-DD')
            },
            columns: [],
            leadsData: [],
            campaignAnalytics: '',
            componentClicked: 'Devices',
            selectedDateClicked: 'Custom period',
            componentsAnalytics: [
                {id: 'Engagement', name: 'Engagement'},
                {id: 'Devices', name: 'Devices'},
                {id: 'Conversions', name: 'Conversions'},
                {id: 'Visitors per country', name: 'Visitors per country'},
                {id: 'Visitors', name: 'Visitors'},
                {id: 'Leads', name: 'Leads'}
            ],
            selectedDateOptions: [
                {id: 'All time', name: 'All time'},
                {id: 'Today', name: 'Today'},
                {id: 'Last 7 days', name: 'Last 7 days'},
                {id: 'Last month', name: 'Last month'},
                {id: 'Custom period', name: 'Custom period'}
            ]
        }
    }

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount() {
        this.campaignId = this.props.match.params.id;
        this.editUrl = `https://editor.brame.ch/?id=${this.campaignId}`;
        this.props.changeTitle('Campaign - Status');
        this.getCampaignData();
    }

    updateDateTime = (props) => {
        this.handleChange(props);
        switch (props.value) {
            case 'Today':
                this.updateStateWithDate([
                    {name: 'startDate', value: moment().format()},
                    {name: 'endDate', value: moment().format()}
                ]);
                break;
            case 'Last 7 days':
                this.updateStateWithDate([
                    {name: 'startDate', value: moment().subtract(7, 'd')},
                    {name: 'endDate', value: moment().format()}
                ]);
                break;
            case 'Last month':
                this.updateStateWithDate([
                    {name: 'startDate', value: moment().subtract(1, 'month')},
                    {name: 'endDate', value: moment().format()}
                ]);
                break;
            case 'All time':
                this.updateStateWithDate([
                    {name: 'startDate', value: moment(this.state.campaignData.start_date).format()},
                    {name: 'endDate', value: moment().format()}
                ]);
                break;
            default:
                this.updateStateWithDate([
                    {name: 'startDate', value: moment(this.state.campaignData.start_date).format()},
                    {name: 'endDate', value: moment().format()}
                ]);
        }
    };

    getCampaignData = () => {
        getDataService.campaignStatusPage({start_date: this.state.analyticsConf.startDate + " 00:00", end_date: this.state.analyticsConf.endDate + " 23:59"},this.campaignId).then((data) => {
            this.setState({
                campaignData: data.data.body
            }, () => {
                this.getAllAnalytics(
                  this.state?.campaignData?.analytics?.viewId,
                  this.state.analyticsConf.startDate,
                  this.state.analyticsConf.endDate
                );
                this.generaliseColumns();
                this.generaliseData();

                this.setState({
                    componentsAnalytics: [
                        {id: 'Engagement', name: 'Engagement'},
                        {id: 'Devices', name: 'Devices'},
                        {id: 'Conversions', name: 'Conversions'},
                        {id: 'Visitors per country', name: 'Visitors per country'},
                        {id: 'Visitors', name: 'Visitors'},
                        {id: 'Leads', name: 'Leads'},
                        {id: this.state.campaignData.game?.name, name: this.state.campaignData.game?.name}
                    ]
                });
            });
        });
    };

    getAllAnalytics = (viewId, startDate, endDate) => {
        googleAnalyticsService.isGAReady().then((res) => {
            if (res.status) {
                googleAnalyticsService.getAllAnalytics(viewId, startDate, endDate)
                  .then((res) => {
                      this.setState({
                          campaignAnalytics: res
                      });
                  })
            }
        });
    };

    handleChange = (event) => {
        const value = event.value;
        const name = event.name;
        this.setState({
            [name]: value
        }, () => this.scroll(this.myRef));
    };

    updateState = (data) => {
        this.setState(prevState =>({
            analyticsConf: {
                ...prevState.analyticsConf,
                [data.name]: moment(data.value).format('YYYY-MM-DD')
            }
        }), () => {
            this.getCampaignData();
        });
    };

    updateStateWithDate = (data) => {
        let _this = this;
        let promise = new Promise(function(resolve, reject) {
            let items = 0;
            data.forEach(date => {
            _this.setState(prevState =>({
                analyticsConf: {
                    ...prevState.analyticsConf,
                    [date.name]: moment(date.value).format('YYYY-MM-DD')
                }
                }));
                items++;
            });
            if (data.length === items) {
                resolve(true);
            }
            else {
                reject(Error("Error"));
            }
        });

         promise.then((res) => {
             if(res) {
                 this.getCampaignData();
             }
         } );
    };

    pauseCampaign = () => {
        setDataService.updateCampaignData({status: 'paused', id: this.campaignId}).then((res: any) => {
            window.location.reload();
        });
    };

    generaliseData = () => {
        if(this.state.campaignData?.leads?.length > 0) {

            let leads = [];
            let filtered = this.state.campaignData.leads.map(({ updatedAt, ...rest }) => ({ ...rest }));

            filtered.forEach((lead) => {
                if (lead.time) {
                    lead.time = moment.duration(parseInt(lead.time), 'seconds')
                      .format("mm:ss")
                }
                leads.push(lead);
            });

            this.setState({
                leadsData: leads
            });
        }
    };

    generaliseColumns = () => {
        if(this.state.campaignData?.leads?.length > 0){
            let filtered = this.state.campaignData.leads.map(({updatedAt,...rest}) => ({...rest}));
            let columns = [];
            let keys = Object.keys(filtered[0]);
            const dataManipulation = (key) => {

                if(key === "createdAt") {
                    key = "date"
                }
                else if(key.includes("_")) {
                    let splitted = key.split("_");
                    let upperedCased = splitted.map(item => item[0].toUpperCase() + item.slice(1))
                    let joinedKey = upperedCased.join(' ')
                    return joinedKey;
                }

                let name = key.slice(0,1).toUpperCase() + key.slice(1);
                return name;
            };
            keys.forEach((key) => {
                columns.push({
                    name: dataManipulation(key),
                    selector: key,
                    sortable: true
                });
            });

            this.setState({
                columns: columns
            });
        }
    };

    customSort (rows, field, direction) {
        const handleField = row => {
            // if (row[field]) {
            //     console.log(row[field]);
            //     return row[field].toLowerCase();
            // }
            return row[field];
        };
        // Deep copy of rows in memory
        let clone = JSON.parse(JSON.stringify(rows));
        let orders = orderBy(clone, handleField, direction);
        orders.map(order => order.createdAt = moment(order.createdAt).format('DD-MM-YYYY HH:mm'));
        return orders;
    };


    renderGameInfo() {
        switch(this.state?.campaignData?.game?.name) {
            case "Guess the picture":
                return (
                    <XcubeAnalyticsGameInfo
                      name={this.state.campaignData.name}
                      data={this.state.campaignData.game_analytics[0]?.correct_analytics}
                    />
                );
            case "Personality Quiz":
                return (
                    <XcubeAnalyticsGameInfo
                      name={this.state.campaignData.game.name}
                      data={this.state.campaignData.game_analytics[0]?.personality_distribution}
                    />
                );
            case "Wheel of fortune":
                return (
                    <XcubeAnalyticsGameInfo
                      name={this.state.campaignData.game.name}
                      data={this.state.campaignData.game_analytics}
                    />
                );
            case "Memory":
                return (
                    <XcubeAnalyticsBestTime
                        campaignData={this.state.campaignData}
                    />
                );
            case "Sliding Puzzle":
                return (
                     <XcubeAnalyticsBestTime
                        campaignData={this.state.campaignData}
                    />
                );
            default: return  (
              <Col>No info</Col>
            )
        }
    }

    showDateFormat = () => {
        if(moment.duration(moment(this.state.analyticsConf.endDate).diff(moment(this.state.analyticsConf.startDate))).asDays() === 0) {
            return '(Hours)'
        }
        else if (moment.duration(moment(this.state.analyticsConf.endDate).diff(moment(this.state.analyticsConf.startDate))).asDays() <= 31) {
            return '(Days)'
        } else {
            return '(Months)'
        }
    };

    renderComponentDetails = () => {
        switch (this.state.componentClicked) {
            case 'Engagement':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsDetailsEngagement
                          data={this.state.campaignAnalytics}
                          showDateFormat={this.showDateFormat()}
                          start_date={this.state.analyticsConf.startDate}
                          end_date={this.state.analyticsConf.endDate}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Devices':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsDevicesDetails
                          data={this.state.campaignAnalytics}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Conversions':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsConversionDetails
                          campaignData={this.state.campaignData}
                          data={this.state.campaignAnalytics}
                          visitsPerMonth={this.state.campaignAnalytics.visitsPerMonth}
                          start_date={this.state.analyticsConf.startDate}
                          end_date={this.state.analyticsConf.endDate}
                          showDateFormat={this.showDateFormat()}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Visitors':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsVisitorsDetails
                          data={this.state.campaignAnalytics}
                          visitsPerMonth={this.state.campaignAnalytics.visitsPerMonth}
                          start_date={this.state.analyticsConf.startDate}
                          end_date={this.state.analyticsConf.endDate}
                          showDateFormat={this.showDateFormat()}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Visitors per country':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsDetailsVisitorsPerCountry
                          data={this.state.campaignAnalytics}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Leads':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsDetailsLeads
                          data={this.state.campaignData}
                          start_date={this.state.analyticsConf.startDate}
                          end_date={this.state.analyticsConf.endDate}
                          showDateFormat={this.showDateFormat()}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Memory':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsBestTimeDetails
                          data={this.state.campaignData}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Guess the picture':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsQuestionDetails
                          questions={this.state.campaignData?.game_analytics[0].question_analytics}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Personality Quiz':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsQuestionDetails
                          questions={this.state.campaignData?.game_analytics[0].answer_distribution}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            case 'Wheel of fortune':
                return (
                  <Col>
                      { this.state.campaignAnalytics
                        ?
                        <XcubeAnalyticsBestTimeDetails
                          data={this.state.campaignData}
                        />
                        :
                        <div style={{textAlign: "center"}}>
                            <ClipLoader
                              size={100}
                              color={"#57ad63"}
                              loading={true}
                            />
                        </div>
                      }
                  </Col>
                );
            default: return <Col>No info</Col>
        }
    };

    render() {
        return (
            <div id="campaignStatus" className="pt-md-5">
                <div className="container">
                    <Row>
                       <div className="col-md-6 status">
                           <h1 className="mb-5">
                            Basics
                           </h1>
                           {this.state?.campaignData?.status === 'active'
                            ? <h2>Your campaign is <strong>live</strong> and doing fine!</h2>
                            : <h2>Your campaign is <strong>{this.state?.campaignData?.status}</strong>!</h2>
                           }
                           <p>Running a <strong>{this.state?.campaignData?.name}</strong> campaign.</p>
                           <p>{this.state?.campaignData?.game?.name} game</p>
                           <div className="btn-group" role="group">
                            <span>Run from <strong>{dataHelper.formatDateHelper(this.state?.campaignData?.start_date, 'DD/MM/YYYY HH:mm')}</strong> to <strong>{dataHelper.formatDateHelper(this.state?.campaignData?.end_date, 'DD/MM/YYYY HH:mm')}</strong></span>
                           </div>
                           <p>Created by {this.state?.campaignData?.user?.first_name + ' ' + this.state?.campaignData?.user?.last_name}</p>
                           <div className="btn-group" role="group">
                               <a href={this.state.campaignData?.campaign_url} className="preview">
                                   <i className="icn-gamepad"></i> View game
                               </a>
                               <div className="share">
                                   <XcubeShareModal
                                     linkText={'Share link'}
                                     qrCode={this.state.campaignData?.qr}
                                     linkShare={this.state.campaignData?.campaign_url}
                                   />
                               </div>
                           </div>
                       </div>
                       <div className="col-md-6 pause">
                           {this.state.campaignData?.status === 'active' &&
                            <XcubeFilledButton
                              btnState="negative"
                              text="Pause"
                              onClick={() => this.pauseCampaign()}
                            />
                           }
                       </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 at-a-glance">
                            <Row>
                                <Col md={6} lg={8}>
                                    <h2 className="mb-md-5">At a glance</h2>
                                </Col>
                                <Col md={6} lg={4}>
                                    <Row className="mb-2">
                                        <Col >
                                            <XcubeDatePicker
                                              label="Start date"
                                              onChange={this.updateState}
                                              value={this.state.analyticsConf.startDate}
                                              name="startDate"
                                              date={this.state.analyticsConf.startDate}
                                              maxDate={this.state.analyticsConf.endDate}
                                            />
                                        </Col>
                                        <Col >
                                            <XcubeDatePicker
                                              label="End date"
                                              onChange={this.updateState}
                                              value={this.state.analyticsConf.endDate}
                                              name="endDate"
                                              date={this.state.analyticsConf.endDate}
                                              minDate={this.state.analyticsConf.startDate}
                                              maxDate={new Date()}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <div id='engagement' className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Engagement'
                                }, () => this.scroll(this.myRef))}
                                     >
                                    { this.state.campaignAnalytics
                                        ?
                                        <XcubeAnalyticsEngagement
                                        data={this.state.campaignAnalytics}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Devices'
                                }, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      <XcubeAnalyticsDevices
                                        data={this.state.campaignAnalytics}
                                      />
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Conversions'
                                }, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      <XcubeAnalyticsConversions
                                        leads={this.state?.campaignData?.leads_count}
                                        data={this.state.campaignAnalytics}
                                        gameVisitors={this.state.campaignData?.game_visitors}
                                      />
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Visitors per country'
                                }, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      <XcubeAnalyticsVisitorsPerCountry
                                        data={this.state.campaignAnalytics}
                                      />
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Visitors'
                                }, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      <XcubeAnalyticsVisitors
                                        data={this.state.campaignAnalytics}

                                      />
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({
                                    componentClicked : 'Leads'
                                }, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      <XcubeAnalyticsLeads
                                        totalLeads={this.state.campaignData?.leads_count}
                                      />
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4" onClick={() => this.setState({componentClicked : this.state.campaignData.game?.name,}, () => this.scroll(this.myRef))}>
                                    { this.state.campaignAnalytics
                                      ?
                                      this.renderGameInfo()
                                      :
                                      <div style={{textAlign: "center"}}>
                                          <ClipLoader
                                            size={100}
                                            color={"#57ad63"}
                                            loading={true}
                                          />
                                      </div>
                                    }
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 statistics-row">
                            <Row>
                                <Col xs={6} md={4}>
                                    <XcubeSelect
                                      label="Metric"
                                      options={this.state.componentsAnalytics}
                                      name="componentClicked"
                                      id="template_id"
                                      onChange={this.handleChange}
                                      required={false}
                                      defaultValue={this.state.componentClicked}
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <XcubeSelect
                                      label="View Mode"
                                      options={this.state.selectedDateOptions}
                                      name="selectedDateClicked"
                                      id="template_id"
                                      onChange={this.updateDateTime}
                                      required={false}
                                      defaultValue={this.state.selectedDateClicked}
                                    />
                                </Col>
                                {this.state.selectedDateClicked === 'Custom period' &&
                                    <Col xs={12} md={4}>
                                        <Row className="status_date_pick">
                                            <Col >
                                                <XcubeDatePicker
                                                  label="Start date"
                                                  onChange={this.updateState}
                                                  value={this.state.analyticsConf.startDate}
                                                  name="startDate"
                                                  date={this.state.analyticsConf.startDate}
                                                  maxDate={this.state.analyticsConf.endDate}
                                                />
                                            </Col>
                                            <Col>
                                                <XcubeDatePicker
                                                  label="End date"
                                                  onChange={this.updateState}
                                                  value={this.state.analyticsConf.endDate}
                                                  name="endDate"
                                                  date={this.state.analyticsConf.endDate}
                                                  minDate={this.state.analyticsConf.startDate}
                                                  maxDate={new Date()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                            <Row ref={this.myRef}>
                                {this.renderComponentDetails()}
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 leads-row">
                            <Row className="leadsRowTable">
                                <div className="col-6">
                                    <h4 className="mb-md-1">Leads</h4>
                                </div>
                                <div className="col-6 export-btn">
                                    <XcubeFilledButton
                                      btnState="normal"
                                      text="Export"
                                      onClick={() => window.location.assign(`${Constants.endPoint + Constants.LEADS.exportLeads(this.campaignId)}`)}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.leadsData.length > 0 &&
                                        <DataTable
                                            columns={this.state.columns}
                                            data={this.state.leadsData}
                                            defaultSortField="title"
                                            sortFunction={this.customSort}
                                            pagination
                                        />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}


export default CampaignStatus;
