import { DISPLAY_SPINNER, EDIT_GA_DATA, EDIT_REGISTRATION } from '../constants/action-types';

const initialState = {
  registrationForm: {
    selected_plan: 'basic',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    company: '',
    website: '',
    contact_email: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    phone: '',
    stripe_name: '',
    stripe_email: '',
    stripe_phone: '',
    stripe_payment_method: {},
    validate: false,
  },
  displaySpinner: false,
  editGAData: {},
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_REGISTRATION:
      return {
        ...state,
        registrationForm: {
          ...state.registrationForm, [action.payload.name]: action.payload.value,
        },
      };
    case DISPLAY_SPINNER:
      return {
        ...state, displaySpinner: action.payload.value,
      };
    case EDIT_GA_DATA:
      return {
        ...state, editGAData: action.payload.value,
      };
    default:
      return state;
  }
}

export default rootReducer;
