import { interceptors } from './shared/helpers/axios-interceptors';

interceptors.reqInterceptor();
interceptors.respInterceptor();

export const Constants = {
  endPoint: 'https://server.brame.ch/api',
  // endPoint: 'http://test.brame.ch/api',
  domain: 'https://server.brame.ch',
  wizard: '/wizard',

  API_KEYS: {
    STRIPE: {
      stripe_pk_test_key: 'pk_test_QX8Vyr7NdWUc1Q9Jyk2QGEtv00dhKG7wyO',
      stripe_sk_test_key: 'sk_test_2fJFSH1Pbc5NBKvcYpxXJno3004Ib6MVcy',
    },

    GA: {
      apiKey: 'AIzaSyCt4uxprTtLZdXqdHy8qdSGh6ZHy3dCmBQ',
      // clientId: '504999701336-0eo5k045s4kbjkhdsdceuom8j82jurhb.apps.googleusercontent.com',
      clientId: '936853369778-579jidk0e7nb38ki2546adcg9htshg63.apps.googleusercontent.com',
      clientSecret: 'KRPlK5u2EkvQjiELv87DmTWb',
    },
  },

  AUTHORIZATION: {
    login: '/login',
    // signUp: '/stripe/charge',
    signUp: '/register',
  },

  IMAGES: {
    upload: '/image',
  },

  UPLOAD: {
    pdf: '/pdf',
  },

  CAMPAIGNS: {
    getActiveCampaigns: '/dashboard/active_campaigns/',
    getInactiveCampaigns: '/dashboard/inactive_campaigns/',
    pauseAllCampaigns: '/dashboard/pauseAll',
    copyCampaign(campaignId) {
      return `/campaign/${campaignId}/copy`;
    },
    campaignAnalytics(campaignId) {
      return `/campaign/${campaignId}/analytics`;
    },
    leadsAndPrizes(campaignId) {
      return `/dashboard/${campaignId}/leads_prizes`;
    },
  },

  USER: {
    userData: '/user_data',
    clientCompanies: '/company_clients',
  },

  TEMPLATES: {
    getAll: '/templates',
  },

  GAMES: {
    getAll: '/games',
    updateGame: '/update_game',
  },

  WIZARD: {
    putData: '/campaign',
  },

  PRIZES: {
    newPrize: '/prize',
  },

  EMAIL: {
    test(campaign_id) {
      return `/dashboard/${campaign_id}/emails/test`;
    },
  },

  ACCOUNT: {
    general: '/account/general',
    updateUser: '/account/general_update/user',
    updateCompany: '/account/general_update/company',
    plan: '/account/plan',
    exportBilling(email) {
      return `/account/plan/${email}/export`;
    },
    updatePayment: '/stripe/update',
    team: '/account/team',
    addEmployee: '/addEmployee',
    editDeleteEmployee(id) {
      return `/user/${id}`;
    },
  },

  PUBLISH: {
    validate(campaignId) {
      return `/dashboard/${campaignId}/check_valid`;
    },
  },

  LEADS: {
    exportLeads(campaignId) {
      return `/dashboard/${campaignId}/status/export_leads`;
    },
    leadsAndPrizes: '/dashboard/leads_total',
  },

  COMPANY: {
    companyAnalytics(companyId) {
      return `/company/${companyId}/analytics`;
    },
    client: '/client',
    activeClientCampaigns(id) {
      return `/client/${id}/campaigns`;
    },
  },

  GROUP: {
    newGroup: '/group',
    newUser: '/group/add_user',
  },
};
