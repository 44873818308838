import React, {Component} from 'react';
import "./xcube-analytics-engagement.scss";
import engagement from './assets/wiz-engagement.png';
import { dataHelper } from '../../helpers/data-helper';

class XcubeAnalyticsEngagement extends Component {
    constructor() {
        super();
        this.state = {
            sessions: ''
        }
    }

    componentDidMount(): void {
        this.getAnalytics();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }

    getAnalytics = () => {
        if(this.props.data.totalSessionDuration.result.error){

        } else{
            this.setState({
                sessions: this.props.data.totalSessionDuration.result.reports[0].data.totals[0].values
            });
        }
    };

    render() {
        return (
            <div id="campaignStatusEngagement" >
                <div className="card">
                    <div className="card-title mt-3 ml-3">Engagement</div>
                    <div className="row no-gutters">
                        <div className="col-7">
                            <div className="card-body pt-0">
                                <p className="card-text"><span style={{color: '#88c559' }}>{dataHelper.secondsToTime(this.state.sessions[0], 'H:mm:ss')}</span> total visit time</p>
                                <p className="card-text"><small>avg. time per visit</small></p>
                                <p className="card-text"><strong className="strongTime"> {dataHelper.secondsToTime(this.state.sessions[1], 'H:mm:ss')}</strong></p>
                            </div>
                        </div>
                        <div className="col-4 pl-2 text-right pr-4 pb-2 ">
                           <img src={engagement} className="engagementImg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsEngagement;
