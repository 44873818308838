import React, { Component} from 'react';
import "./xcube-analytics-visitors-details.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import moment from 'moment';

class XcubeAnalyticsVisitorsDetails extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      lastSevenDays: false
    }
  }

  componentDidMount(): void {
    this.getAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.getAnalytics();
      // console.log(this.props.data)
    }
  }

  showDateFormat = () => {
    return this.props.showDateFormat
  }

  getAnalytics = () => {

    let dataArr = [];
    if(this.props.data.visitsPerMonth.result.error) {

    } else {
      if(this.props.data.visitsPerMonth.result?.reports[0]?.data?.rows) {
        this.props.data.visitsPerMonth.result.reports[0].data.rows.map((row, index) => {
          if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() === 0)  {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : row.dimensions[0],
              parseInt(row.metrics[0].values[0])
            ]);
          } else if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() <= 31) {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('DD MMM'),
              parseInt(row.metrics[0].values[0])
            ]);
          } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() > 31)  {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('MMM'),
              parseInt(row.metrics[0].values[0])
            ]);
          }

          this.setState({
            data: [['Date', 'Visitors'], ...dataArr]
          })
        });
      }
    }
  };

  render() {
    return(
      <Row>
        <Col lg={12} className="text-center pt-3">
          <h5 >Visitors</h5>
          {this.state.data.length > 0
          ?  <Chart
              className='visitorsDetails'
              chartType="AreaChart"
              style={{margin: '0 auto'}}
              loader={<div>Loading Chart</div>}
              data={this.state.data}
              options={{
                legend: {
                  position: 'top'
                },
                hAxis: { title: ` ${this.showDateFormat()} \n From ${moment(this.props.start_date).format('MM-DD-YYYY')} to ${moment(this.props.end_date).format('MM-DD-YYYY')}`, titleTextStyle: { color: '#333', bold: true } },
                vAxis: { minValue: 0, title: 'Visitors', titleTextStyle: {bold: true, fontSize: 14} },
                chartArea: { width: '80%', height: '70%' , bottom: '25%' },
              }}
              rootProps={{ 'data-testid': '1' }}
            />
            : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsVisitorsDetails;
