import {
    Route,
    Redirect
} from 'react-router-dom';
import {authenticationService} from "../services/authenticationService";
import React from "react";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        authenticationService.isUserAuth()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
            }} />
    )} />
);