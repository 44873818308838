import React, {Component} from 'react';
import "./xcube-analytics-leads.scss";
import image from '../xcube-analytics-leads/assets/wiz-path.png';
class XcubeAnalyticsLeads extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="campaign-leads">
                <div className="card">
                    <div className="card-title-visitors mt-3 ml-3">Leads</div>
                    <div className="row no-gutters">
                        <div className="col-7">
                            <div className="card-body pt-4">
                                <p className="card-text"><strong className="strongVisitors">{this.props.totalLeads}</strong></p>
                                <p className="card-text"><small>entered their data</small></p>
                            </div>
                        </div>
                        <div className="col-4 text-right pr-4 pb-2 pt-2">
                           <img src={image} className="leadsImg" alt="" style={{width: '100px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsLeads;
