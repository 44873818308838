import React, {Component} from 'react';
import devices from "./assets/wiz-device.png";
import "./xcube-analytics-devices.scss";


class XcubeAnalyticsDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: []
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }

    getAnalytics = () => {
        this.setState({
            devices: []
        }, () => {
            if(!this.props.data.devices?.result?.error) {
                if(this.props.data.devices?.result?.reports[0].data.rows) {
                    this.props.data.devices.result.reports[0].data.rows.map((row, index) => {
                        this.setState((prevState) => ({
                            devices: [...prevState.devices, {
                                name: row.dimensions[0], value: row.metrics[0].values[0]
                            }],
                        }));
                    });
                }
            }
        })
    };

    renderData = () => {
        let data = [];
        if(this.state.devices.length > 0) {
            this.state.devices.map((device, index) => {
                data.push(
                  <p key={index} className="card-text"><i className={"icn-" + device.name}> </i> <span>{device.value}</span> {device.name}</p>
                );
            });
        } else {
            data = [
                <p key="desktop" className="card-text"><i className="icn-desktop"> </i> <span>0</span> desktop</p>,
                <p key="tablet" className="card-text"><i className="icn-tablet"> </i> <span>0</span> tablet</p>,
                <p key="mobile" className="card-text"><i className="icn-mobile"> </i> <span>0</span> phone</p>
            ]
        }
        return data;
    };

    render() {
        return (
            <div id="dashboard-devices">
                <div className="card">
                    <div className="card-title-devices mt-3 ml-3">Devices</div>
                    <div className="row no-gutters">
                        <div className="col-7">
                            <div className="card-body pt-4">
                                {this.renderData()}
                            </div>
                        </div>
                        <div className="col-4 text-right pl-2 pr-4 pb-2 pt-2">
                           <img src={devices} className="deviceImg" alt="deviceImg"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsDevices;
