import React, {Component} from 'react';
import "./xcube-analytics-visitors-per-country.scss";
class XcubeAnalyticsVisitorsPerCountry extends Component {
    constructor() {
        super();
        this.state = {
            country: []
        }
    }

    componentDidMount(): void {
        this.getAnalytics();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }

    getAnalytics = () => {
        if(this.props.data.usersPerCountry.result.error) {
        }
        else{
            this.setState({
               country: []
            }, () => {
                if(this.props.data.usersPerCountry?.result?.reports[0].data.rows) {
                    this.props.data.usersPerCountry.result.reports[0].data.rows.map((row, index) => {
                        this.setState((prevState) => ({
                            country: [...prevState.country, {
                                name: row.dimensions[0], value: row.metrics[0].values[0]
                            }],
                        }));
                    });
                }
            });
        }
    };

    renderData = () => {
        let data = {
            names: [],
            values: []
        };

        if(this.state.country.length > 0) {
            this.state.country.map((country, index) => {
                if(index < 3){
                    data.names.push(
                      <p className="card-text" key={country.name}>{country.name}</p>
                    );
                    data.values.push(
                      <p className="card-text" key={country.name + country.value}><strong>{country.value}</strong></p>
                    )
                }
            });
        } else {
            data.names = [
                <p key={'no_data'}>No data to show yet.</p>
            ]
        }
        return data;
    };

    render() {
        return (
            <div id="campaignCountry">
                <div className="card">
                    <div className="card-body">
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5 className="card-title">Visitors per country</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8">
                                {this.renderData().names}
                            </div>
                            <div className="col-4 text-right">
                                {this.renderData().values}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsVisitorsPerCountry;
