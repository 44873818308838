import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import './dashboard-page.scss';
import XcubeFilledButton from '../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeChartjsGeo from '../../../../shared/components/xcube-chartjs-geo/xcube-chartjs-geo';
import gameMemoryImg from '../dashboard-campaign/components/campaigns/assets/game-memory.svg';
import { getDataService } from '../../../../shared/services/getDataService';
import { dataHelper } from '../../../../shared/helpers/data-helper';
import { setDataService } from '../../../../shared/services/setDataService';
import { googleAnalyticsService } from '../../../../shared/services/googleAnalyticsService';
import InsertAccountIdModal from './components/insertAccountIdModal/insertAccountIdModal';
import { ClipLoader } from 'react-spinners';
import XcubeAnalyticsConversions
  from '../../../../shared/components/xcube-analytics-conversions/xcube-analytics-conversions';
import XcubeAnalyticsDevices
  from '../../../../shared/components/xcube-analytics-devices/xcube-analytics-devices';
import XcubeAnalyticsVisitors
  from '../../../../shared/components/xcube-analytics-visitors/xcube-analytics-visitors';
import XcubeAnalyticsLeads
  from '../../../../shared/components/xcube-analytics-leads/xcube-analytics-leads';
import XcubeAnalyticsEngagement
  from '../../../../shared/components/xcube-analytics-engagement/xcube-analytics-engagement';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import XcubeDatePicker from '../../../../shared/components/xcube-date-picker/xcube-date-picker';
import XcubeRenderCampaigns
  from '../../../../shared/components/xcube-render-campaigns/xcube-render-campaigns';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignsData: [],
      campaignsStatus: [],
      gaData: '',
      analyticsConf: {
        startDate: '2020-04-01',
        endDate: moment().format('YYYY-MM-DD')
      },
      total_leads: '',
      dashboardAnalytics: ''
    }
  }

  updateState = (data) => {
    this.setState(prevState =>({
      analyticsConf: {
        ...prevState.analyticsConf,
        [data.name]: moment(data.value).format('YYYY-MM-DD')
      }
    }), () => {
      this.getLeadsAndPrizes();
      googleAnalyticsService.isGAReady().then((res) => {
        if (res.status) {
          this.getAllAnalytics(res.GAData.defaultViewId, this.state.analyticsConf.startDate, this.state.analyticsConf.endDate);
        }
      });
    });
  };

  getAllAnalytics = (viewId, startDate, endDate) => {
    googleAnalyticsService.getAllAnalytics(viewId, startDate, endDate).then((res) => {
      this.setState({
        dashboardAnalytics: res
      });
    })
  };

  componentDidMount() {
    this.getLeadsAndPrizes();
    getDataService.dashboardPage().then((resp) => {
      this.setState((prevState) => ({
        campaignsStatus: [...prevState.campaignsStatus, resp.data],
      }));

      googleAnalyticsService.isGAReady().then((res) => {
        if(res.status){
          if(resp.data.latestCampaigns.length > 0){
            // resp.data.latestCampaigns.map((data) => {
              // data.game.thumbnail_url = gameMemoryImg;
            // });
            googleAnalyticsService.readVisitsArrayPerCampaign(resp.data.latestCampaigns).then((response) => {
              resp.data.latestCampaigns.map(resp => {
                if(response[resp.id].result.error){
                  resp.analytics.visits = 0;
                  resp.analytics.conversions = 0;
                  this.setState((prevState) => ({
                    campaignsData: [...prevState.campaignsData, resp],
                  }))
                } else{
                  if(response[resp.id].result?.reports[0]?.data?.rows) {
                    resp.analytics.visits = response[resp.id].result.reports[0]?.data?.rows[0]?.metrics[0]?.values[0];
                    resp.analytics.conversions = dataHelper.conversionCalculator(resp.leads_count > resp.game_visitors ? resp.leads_count : resp.game_visitors, resp.leads_count);
                    this.setState((prevState) => ({
                      campaignsData: [...prevState.campaignsData, resp],
                    }))
                  } else{
                    resp.analytics.visits = 0;
                    resp.analytics.conversions = 0;
                    this.setState((prevState) => ({
                      campaignsData: [...prevState.campaignsData, resp],
                    }))
                  }
                }
              });
          })}
          this.setState({
            gaData: res.GAData,
          });
          this.getAllAnalytics(res.GAData.defaultViewId, this.state.analyticsConf.startDate, this.state.analyticsConf.endDate);
        } else{
          document.getElementById('GAAccountId').click(); // Ask user to add Account ID.
        }
      });
    });
  }

  getLeadsAndPrizes = () => {
    getDataService.getLeadsAndPrizes({start_date: this.state.analyticsConf.startDate + " 00:00", end_date: this.state.analyticsConf.endDate + " 23:59"}).then((resp) => {
      this.setState({
        total_leads: resp.data.body.total_leads,
      });
    });
  };

  pauseAll = () => {
    setDataService.pauseAllCampaigns().then((resp) => {
      window.location.reload();
    })
  };

  renderStatusInfo = () => {

    return (
      this.state.campaignsStatus[0]?.active > 1
        ? <h2>You have {this.state.campaignsStatus[0]?.active} campaigns running great!</h2>
        : this.state.campaignsStatus[0]?.active === 0 ? <h2>You have no campaigns running!</h2> : <h2>You have {this.state.campaignsStatus[0]?.active} campaign running great!</h2>
    )

  };

  render() {
    return (
      <div>
        <div className="mt-md-2 mb-md-5 page-title">
          Dashboard
        </div>
        <div id="dashboard-page" className="pt-md-5">
          <div className="container">
            <Row className="pt-4">
              <InsertAccountIdModal />
              <div className="col-sm-6 col-md-6 status">
                <h1 >
                  Status
                </h1>
                {this.renderStatusInfo()}
                { this.state.campaignsStatus[0]?.lastDate != null &&
                <p>Last campaign ended on {dataHelper.formatDateHelper(this.state.campaignsStatus[0]?.lastDate, 'd/MM/YYYY')}.</p>
                }
              </div>
              <div className="col-sm-6 col-md-6 pause">
                <Row>
                  <div className="col-sm-12 col-md-12">
                  <XcubeFilledButton
                      btnState="normal"
                      text="New campaign"
                      onClick={() => window.open('/wizard', '_self')} />
                       {this.state.campaignsStatus[0]?.active > 0 &&
                      <XcubeFilledButton
                        btnState="negative"
                        text="Pause"
                        onClick={() => this.pauseAll()}/>
                      }
                  </div>
                </Row>
              </div>
            </Row>

            <Row className="pb-3">
              <Col  xs={12} sm={12} md={8}></Col>
              <Col xs={12} sm={12} md={4} className="mt-4">
                <Row>
                  <Col xs={6}>
                    <XcubeDatePicker
                      label="Start date"
                      onChange={this.updateState}
                      value={this.state.analyticsConf.startDate}
                      name="startDate"
                      date={this.state.analyticsConf.startDate}
                      maxDate={this.state.analyticsConf.endDate}
                    />
                  </Col>
                  <Col xs={6}>
                    <XcubeDatePicker
                      label="End date"
                      onChange={this.updateState}
                      value={this.state.analyticsConf.endDate}
                      name="endDate"
                      date={this.state.analyticsConf.endDate}
                      minDate={this.state.analyticsConf.startDate}
                      maxDate={new Date()}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-md-4">
              <div className="col-sm-4 col-md-6 col-lg-4">
                { this.state.dashboardAnalytics !== ''
                  ?
                    <XcubeAnalyticsVisitors
                      data={this.state.dashboardAnalytics}
                    />
                  :
                  <div style={{textAlign: "center"}}>
                    <ClipLoader
                      size={100}
                      color={"#57ad63"}
                      loading={true}
                    />
                  </div>
                }
              </div>

              <div className="col-sm-4 col-md-6 col-lg-4">
                { this.state.dashboardAnalytics !== ''
                  ?
                    <XcubeAnalyticsDevices
                      leads={this.state.total_leads}
                      data={this.state.dashboardAnalytics}
                    />
                  :
                  <div style={{textAlign: "center"}}>
                    <ClipLoader
                      size={100}
                      color={"#57ad63"}
                      loading={true}
                    />
                  </div>
                }
              </div>

              <div className="col-sm-4 col-md-6 col-lg-4">
                { this.state.dashboardAnalytics
                  ?
                  <XcubeAnalyticsConversions
                    leads={this.state?.total_leads}
                    data={this.state.dashboardAnalytics}
                    gameVisitors={this.state.campaignsStatus[0]?.total_game_visitors}
                  />
                  :
                  <div style={{textAlign: "center"}}>
                    <ClipLoader
                      size={100}
                      color={"#57ad63"}
                      loading={true}
                    />
                  </div>
                }
              </div>

              <div className="col-sm-4 col-md-6 col-lg-4">
                { this.state.dashboardAnalytics !== ''
                  ?
                  <XcubeAnalyticsEngagement
                    data={this.state.dashboardAnalytics}
                  />
                  :
                  <div style={{textAlign: "center"}}>
                    <ClipLoader
                      size={100}
                      color={"#57ad63"}
                      loading={true}
                    />
                  </div>
                }
              </div>

              <div className="col-sm-4 col-md-6 col-lg-4">
                { this.state.dashboardAnalytics !== ''
                  ?
                  <XcubeAnalyticsLeads
                    totalLeads={this.state.total_leads}
                  />
                  :
                  <div style={{textAlign: "center"}}>
                    <ClipLoader
                      size={100}
                      color={"#57ad63"}
                      loading={true}
                    />
                  </div>
                }
              </div>
            </Row>
            {/*<Row>*/}
            {/*  <div className="col-md-12">*/}
            {/*    <hr />*/}
            {/*  </div>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <div className="col-md-12 statistics-row">*/}
            {/*    <Row>*/}
            {/*      <div className="col-md-6">*/}
            {/*        <h2 className="mb-md-3 mt-md-3">Overview</h2>*/}
            {/*      </div>*/}
            {/*    </Row>*/}
            {/*  </div>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <div className="col-md-12">*/}
            {/*    { this.state.dashboardAnalytics !== ''*/}
            {/*      ?*/}
            {/*      <XcubeChartjsGeo*/}
            {/*        data={this.state.dashboardAnalytics}*/}
            {/*      />*/}
            {/*      :*/}
            {/*      <div style={{textAlign: "center"}}>*/}
            {/*        <ClipLoader*/}
            {/*          size={100}*/}
            {/*          color={"#57ad63"}*/}
            {/*          loading={true}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</Row>*/}
            <Row>
              <div className="col-md-12 mt-md-4 mb-md-3">
                <hr />
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-md-6 section-head">
                <h1>
                  Latest campaigns
                </h1>
              </div>
              <div className="col-md-6 pb-2 allCampaignsBtn">
                <XcubeFilledButton
                  btnState="normal"
                  text="All campaigns"
                  onClick={() => window.location = 'campaigns'}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12 mt-md-3 tableXoverflow">
                <table className="table" id="leads-table">
                  <thead>
                    <tr>
                      <th scope="col">Campaign</th>
                      <th scope="col">Game</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Conversions</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <XcubeRenderCampaigns data={this.state.campaignsData} />
                  </tbody>
                </table>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardPage;
