import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import "./xcube-sweetalert.scss";
function XcubeSweetAlert(props) {
    return (
      <div id="xcube-sweetalert">
        <SweetAlert
          title={props.title}
          showCancel
          confirmBtnText={props.confirmBtnText}
          confirmBtnBsStyle={props.confirmBtnBsStyle}
          focusCancelBtn
          onConfirm={() => props.response('confirm')}
          onCancel={() => props.response('cancel')}
          cancelBtnCssClass={"alert_cancel_btn"}
          confirmBtnCssClass={"alert_confirm_btn"}
          show={props.show}
        >
          {props.text}
        </SweetAlert>
      </div>
    );
}

export default XcubeSweetAlert;
