import React, {Component} from 'react';
import "./xcube-chartjs-geo.scss";
import { googleAnalyticsService } from '../../services/googleAnalyticsService';
import Chart from 'react-google-charts';

class XcubeChartjsGeo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            country: [
                ["Country", "Popularity",  { role: "tooltip", type: "string", p: { html: true } }],
            ]
        };
    }

    getAnalytics = () => {
        this.setState({
            country: [
                ["Country", "Popularity" ],
            ]
        });

        if(!this.props.data.visits.result.error){
            if(this.props.data.usersPerCountry.result?.reports[0]?.data?.rows) {
                this.props.data.usersPerCountry.result.reports[0].data.rows.map((row, index) => {
                    this.setState((prevState) => ({
                        country: [...prevState.country, [
                            row.dimensions[0], parseInt(row.metrics[0].values[0], 10)
                        ]],
                    }));
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }

    componentDidMount(): void {
        this.getAnalytics();
    }

    render() {
        return (
            <div>
                <div id="xcubeGeoChart">
                    <Chart
                      chartEvents={[
                          {
                              eventName: "select",
                              callback: ({ chartWrapper }) => {
                                  const chart = chartWrapper.getChart();
                                  const selection = chart.getSelection();
                                  if (selection.length === 0) return;
                                  const region = this.state.country[selection[0].row + 1];
                              }
                          }
                      ]}
                      chartType="GeoChart"
                      width="100%"
                      height="60%"
                      data={this.state.country}
                      options={{
                          sizeAxis: { minValue: 0, maxValue: 100 },
                          colorAxis: { colors: ['#a0e869', '#577e39'] },
                          // tooltip: { isHtml: true},
                          region: '150', // Europe
                          // displayMode: 'markers',
                          }}

                      mapsApiKey="AIzaSyCt4uxprTtLZdXqdHy8qdSGh6ZHy3dCmBQ"
                      rootProps={{ 'data-testid': '3' }}
                    />
                </div>
            </div>
        );
    }
}

export default XcubeChartjsGeo;
