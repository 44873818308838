import React, {Component} from 'react';
import pie from "./assets/wiz-conversion.png";
import "./xcube-analytics-conversions.scss";
import { dataHelper } from '../../helpers/data-helper';

class XcubeAnalyticsConversions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalConversions: '0',
            lastDayConversion: '0',
            todayConversion: '0',
            visitors: '0'
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }
    getAnalytics = () => {
        if(this.props.data.visits.result.error){
            this.setState({
                visitors: 0,
                totalConversions: dataHelper.conversionCalculator(this.props.gameVisitors < this.props.leads ? this.props.leads : this.props.gameVisitors , this.props.leads),
            });
        } else{
            this.setState({
                totalConversions: dataHelper.conversionCalculator(this.props.gameVisitors < this.props.leads ? this.props.leads : this.props.gameVisitors , this.props.leads),
                visitors: this.props.data.visits.result.reports[0].data.totals[0].values[0]
            });
        }
    };
    componentDidMount() {
        this.getAnalytics();
    }

    render() {
        return (
            <div id="dashboardConversions">
                 <div className="card">
                    <div className="card-title-visitors mt-3 ml-3">Conversions</div>
                    <div className="row no-gutters">
                        <div className="col-7">
                            <div className="card-body pt-2">
                                <p className="card-text"><small><strong>{this.state.visitors}</strong> visitors</small></p>
                                <p className="card-text"><small><strong>{this.props.gameVisitors < this.props.leads ? this.props.leads : this.props.gameVisitors} </strong>players</small></p>
                                <p className="card-text"><small><strong>{this.props.leads} </strong>leads</small></p>
                                <p className="card-text"><small><strong id='conv'>{this.state.totalConversions} % </strong>conversion</small></p>
                            </div>
                        </div>
                        <div className="col-4 text-right pr-4 pb-2 pt-2">
                           <img src={pie} className="visitorImg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsConversions;
