import React, { Component } from 'react';
import './campaigns.scss';
import Row from 'react-bootstrap/Row';
import addImg from './assets/add.svg';
import { getDataService } from '../../../../../../shared/services/getDataService';
import XcubeFilledButton
  from '../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import { googleAnalyticsService } from '../../../../../../shared/services/googleAnalyticsService';
import { dataHelper } from '../../../../../../shared/helpers/data-helper';
import XcubeRenderCampaigns
  from '../../../../../../shared/components/xcube-render-campaigns/xcube-render-campaigns';
import Pagination from "react-js-pagination";

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCampaigns: [],
      inactiveCampaigns: [],
      activeCampaignsCurrentPage: 0,
      inactiveCampaignsCurrentPage: 0
    };
  }

  handlePageChange(pageNumber, type) {
    if(type === 'active') {
      this.setState({activeCampaigns: []}, () => {
        this.getActiveCampaigns(pageNumber-1);
        this.setState({activeCampaignsCurrentPage: pageNumber});
      });
    } else {
      this.setState({inactiveCampaigns: []}, () => {
        this.getInactiveCampaings(pageNumber-1);
        this.setState({inactiveCampaignsCurrentPage: pageNumber});
      });
    }
  }

  getActiveCampaigns = (page) => {
    getDataService.getActiveCampaigns(page).then((resp) => {
      googleAnalyticsService.isGAReady()
        .then((res) => {
          if (res.status) {
            googleAnalyticsService.readVisitsArrayPerCampaign(resp.data).then((response) => {
              resp.data.map((resp) => {
                if (response[resp.id].result.error) {
                  resp.analytics.visits = 0;
                  resp.analytics.conversions = 0;
                  this.setState((prevState) => ({
                    activeCampaigns: [...prevState.activeCampaigns, resp],
                  }))
                } else {
                  if (response[resp.id].result?.reports[0]?.data?.rows) {
                    resp.analytics.visits = response[resp.id].result.reports[0]?.data?.rows[0]?.metrics[0]?.values[0];
                    resp.analytics.conversions = dataHelper.conversionCalculator(resp.visitors_count < resp.leads_count ? resp.leads_count : resp.visitors_count, resp.leads_count);

                    this.setState((prevState) => ({
                      activeCampaigns: [...prevState.activeCampaigns, resp],
                    }))
                  } else {
                    resp.analytics.visits = 0;
                    resp.analytics.conversions = 0;
                    this.setState((prevState) => ({
                      activeCampaigns: [...prevState.activeCampaigns, resp],
                    }))
                  }
                }
              })
            })
          }
        })
    });
  }

  getInactiveCampaings = (page) => {
    getDataService.getInactiveCampaigns(page).then((resp) => {
      googleAnalyticsService.isGAReady()
        .then((res) => {
          if (res.status) {
            googleAnalyticsService.readVisitsArrayPerCampaign(resp.data).then((response) => {
              resp.data.map((resp) => {
                if(response[resp.id].result.error){
                  resp.analytics.visits = 0;
                  resp.analytics.conversions = 0;
                  this.setState((prevState) => ({
                    inactiveCampaigns: [...prevState.inactiveCampaigns, resp],
                  }))
                } else{
                  if(response[resp.id].result?.reports[0]?.data?.rows) {
                    resp.analytics.visits = response[resp.id].result.reports[0]?.data?.rows[0]?.metrics[0]?.values[0];
                    resp.analytics.conversions = dataHelper.conversionCalculator(resp.visitors_count < resp.leads_count ? resp.leads_count : resp.visitors_count, resp.leads_count);
                    this.setState((prevState) => ({
                      inactiveCampaigns: [...prevState.inactiveCampaigns, resp],
                    }))
                  } else{
                    resp.analytics.visits = 0;
                    resp.analytics.conversions = 0;
                    this.setState((prevState) => ({
                      inactiveCampaigns: [...prevState.inactiveCampaigns, resp],
                    }))
                  }
                }
              });
            })
          }}
        )
    })
  }

  componentDidMount() {
    this.getActiveCampaigns(0);
    this.getInactiveCampaings(0);
  }

  getCampaignsAnalytics = (viewId) => {
    return googleAnalyticsService.isGAReady()
      .then((res) => {
        if (res.status) {
          return googleAnalyticsService.readAnalyticVisits(
            viewId,
            '2015-01-01',
            'today'
          )
            .then((resp) => {
              if (resp.visits.result.error) {
                return 0;
              } else {
                return resp.visits.result.reports[0].data.totals[0].values[0];
              }
            })
        } else {
          document.getElementById('GAAccountId').click(); // Ask user to add Account ID.
        }
      });
  };

  render() {
    return (
      <div id="campaignMain" className="pt-md-5">
        <div className="container">
          <Row>
            <div className="col-md-6 section-head mt-3">
              <h1>
                Live campaigns
              </h1>
            </div>
            <div className="col-md-6 mb-3 new-campaign-btn">
              <XcubeFilledButton btnState="normal" text="New campaign" onClick={() => window.location.assign('/wizard')}/>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-3 liveCampaignsXoverflow">
              <table className="table" id="leads-table">
                <thead>
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Game</th>
                  <th scope="col">Visitors</th>
                  <th scope="col">Conversions</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                  <XcubeRenderCampaigns data={this.state.activeCampaigns} />
                </tbody>
              </table>
              <Pagination
                activePage={this.state.activeCampaignsCurrentPage}
                itemsCountPerPage={5}
                totalItemsCount={50}
                pageRangeDisplayed={5}
                onChange={(page) => this.handlePageChange(page, 'active')}
              />
            </div>
          </Row>

          <Row>
            <div className="col-md-6 section-head">
              <h1>
                Inactive campaigns
              </h1>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-3 liveCampaignsXoverflow">
              <table className="table" id="leads-table">
                <thead>
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Game</th>
                  <th scope="col">Visitors</th>
                  <th scope="col">Conversions</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <XcubeRenderCampaigns data={this.state.inactiveCampaigns} />
                </tbody>
              </table>
              <Pagination
                  activePage={this.state.inactiveCampaignsCurrentPage}
                  itemsCountPerPage={5}
                  totalItemsCount={50}
                  pageRangeDisplayed={5}
                  onChange={(page) => this.handlePageChange(page, 'inactive')}
              />
            </div>
          </Row>
          <Row>
            <div className="media media-add" onClick={() => {window.location.assign('/wizard')}}>
              <img src={addImg} alt="" />
              <div className="media-body">
                <h5 className="mt-0">
                  Want to run a new campaign?
                </h5>
                Click here and we will get you started in no time!
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default Campaigns;
