import Moment from 'react-moment';
import React from 'react';
import * as moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const dataHelper = {
  formatDateHelper,
  daysLeftHelper,
  capitalizeString,
  isJsonString,
  conversionCalculator,
  secondsToTime,
  validURL
};

function formatDateHelper(dateToFormat, format) {
  // console.log(dateToFormat);
  return (
    moment(dateToFormat).format(format)
    // <Moment format={format}>
    //   {dateToFormat}
    // </Moment>
  );
}

function daysLeftHelper(toDate) {
  return (
    <Moment fromNow>
      {toDate}
    </Moment>
  );
}

function capitalizeString(s){
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1)
};

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function conversionCalculator (players, leads) {
  let result = ((parseInt(leads)/parseInt(players))*100).toFixed(1);
  return result === 'Infinity' || result === 'NaN' ? 0 : result;
}

function secondsToTime(seconds, format){
  return moment("2015-01-01").startOf('day')
    .seconds(Math.round(seconds))
    .format(format);
}

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
