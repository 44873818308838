import React, { Component} from 'react';
import "./xcube-analytics-details-leads.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import moment from 'moment';


class XcubeAnalyticsDetailsLeads extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    }
  }

  componentDidMount(): void {
    this.getAnalytics();
    // console.log('leads details', this.props.data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.getAnalytics();
      // console.log('leads details updated', this.props.data)
    }
  }

  showDateFormat = () => {
    return this.props.showDateFormat
  }

  getAnalytics = () => {
    let dataArr = [];

      if(this.props.data.leads_distribution.length > 0) {
        this.props.data.leads_distribution.map((lead) => {
          if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() === 0) {
            dataArr.push([
              lead.date,
              lead.leads
            ]);
          } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() <= 31) {
            dataArr.push([
              lead.date,
              lead.leads
            ]);
          } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() > 31)  {
            dataArr.push([
              moment(lead.date).format('MMM'),
              lead.leads
            ]);
          }


          this.setState({
            data: [['Date', 'Leads'], ...dataArr]
          })
        });
      }

  };

  render() {
    return(
      <Row>
        <Col lg={12} className="text-center pt-3">
          <h5>Leads</h5>
          {this.state.data.length > 0
            ?  <Chart
              className='visitorsDetails'
              chartType="AreaChart"
              style={{margin: '0 auto'}}
              loader={<div>Loading Chart</div>}
              data={this.state.data}
              options={{
                legend: {
                  position: 'top'
                },
                hAxis: { title:  ` ${this.showDateFormat()} \n From ${moment(this.props.start_date).format('MM-DD-YYYY')} to ${moment(this.props.end_date).format('MM-DD-YYYY')}`, titleTextStyle: { color: '#333', bold: true } },
                vAxis: { minValue: 0, title: 'Leads', titleTextStyle: {bold: true, fontSize: 14} },
                chartArea: { width: '80%', height: '70%', bottom: '25%' },
              }}
              rootProps={{ 'data-testid': '1' }}
              chartPackages={['corechart', 'controls']}
            />
            : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsDetailsLeads;
