import React, {Component} from 'react';
import './dashboard.scss';
import DashboardNavbar from "./components/navbar/dashboard-navbar";
import DashboardCampaign from "./components/dashboard-campaign/dashboard-campaign";
import {
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import DashboardPage from "./components/dashboard/dashboard-page";
import DashboardAccounts from "./components/dashboard-accounts/dashboard-accounts";
import {authenticationService} from "../../shared/services/authenticationService";
import {PrivateRoute} from "../../shared/helpers/private-route";
import CreateCampaignWizard from "../create-campaign-wizard/create-campaign-wizard";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }
    render() {

        return (
            <div id="dashboard">
                <div className="container-fluid">
                    <Router>
                        <DashboardNavbar/>
                        <Switch>
                            <PrivateRoute path='/dashboard' component={DashboardPage}/>
                            <PrivateRoute path='/campaigns' component={DashboardCampaign}/>
                            <PrivateRoute path='/account' component={DashboardAccounts}/>
                            <PrivateRoute path='/wizard' component={CreateCampaignWizard}/>
                        </Switch>
                    </Router>
                </div>
            </div>
        );
    }
}

export default Dashboard;
