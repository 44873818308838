import React, { Component} from 'react';
import "./xcube-analytics-conversion-details.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import moment from 'moment';


class XcubeAnalyticsConversionDetails extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    }
  }

  componentDidMount(): void {
    this.getAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.getAnalytics();
    }
  }

  showDateFormat = () => {
    return this.props.showDateFormat
  }


  getAnalytics = () => {
    if(this.props.visitsPerMonth?.result?.error) {
    }
    else{
      let dataArr = [];

      if(this.props.visitsPerMonth?.result?.reports[0]?.data?.rows) {
        this.props.visitsPerMonth.result.reports[0].data.rows.map((row, index) => {

          if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() === 0) {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : row.dimensions[0],
              parseInt(row.metrics[0].values[0]),
              parseInt(this.props.campaignData.leads_distribution[index]?.leads),
              parseInt(this.props.campaignData.leads_distribution[index]?.players)
            ]);
          } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() <= 31) {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('DD MMM'),
              parseInt(row.metrics[0].values[0]),
              parseInt(this.props.campaignData.leads_distribution[index]?.leads),
              parseInt(this.props.campaignData.leads_distribution[index]?.players)
            ]);
          } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() > 31) {
            dataArr.push([
              row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('MMM'),
              parseInt(row.metrics[0].values[0]),
              parseInt(this.props.campaignData.leads_distribution[index]?.leads),
              parseInt(this.props.campaignData.leads_distribution[index]?.players)
            ]);
          }

          this.setState({
            data: [['Date', 'Visitors', 'Leads', 'Players'], ...dataArr]
          })
        });
      }
    }
  };

  render() {
      return(
      <Row>
          <Col lg={12} className="text-center pt-3">
            <h5 >Conversions</h5>
            {this.state.data.length > 0
              ?  <Chart
                className="detailsConversion"
                chartType="AreaChart"
                style={{margin: '0 auto'}}
                loader={<div>Loading Chart</div>}
                data={this.state.data}
                options={{
                  hAxis: { title: ` ${this.showDateFormat()} \n From ${moment(this.props.start_date).format('MM-DD-YYYY')} to ${moment(this.props.end_date).format('MM-DD-YYYY')}`, titleTextStyle: { color: '#333', bold: true } },
                  vAxis: { minValue: 0, title: 'Count', titleTextStyle: {bold: true, fontSize: 15} },
                  chartArea: { width: '70%', height: '70%', bottom:"25%" },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              : <p>No data to show yet</p>
            }
          </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsConversionDetails;
