import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { Constants } from '../../Constants';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
  signUp,
  login,
  logout,
  userData,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; },
  isUserAuth,
};

function isUserAuth() {
  return localStorage.getItem('currentUser');
}

function login(data) {
  return axios.post(Constants.endPoint + Constants.AUTHORIZATION.login, {
    email: data.email,
    password: data.password,
  }).then((res) => {
    // console.log(res);
    localStorage.setItem('currentUser', JSON.stringify(res.data.body));
    currentUserSubject.next(res.data.body);
    return res.data.body;
  });
}

function userData() {
  return axios.get(Constants.endPoint + Constants.USER.userData)
    .then((resp) => {
      // console.log(resp);
    });
}

function signUp(data) {
  return axios.post(Constants.endPoint + Constants.AUTHORIZATION.signUp, {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    password: data.password,
  }).then((user) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
    return user;
  });
}

function logout() {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
  //obrisi mu i sesiju google analitike!!
}
