import React, { Component} from 'react';
import "./xcube-analytics-details-engagement.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import {dataHelper} from "../../helpers/data-helper";
import moment from 'moment';

class XcubeAnalyticsDetailsEngagement extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      vAxisTitle: 'Time in minutes'
    }
  }

  componentDidMount(): void {
    this.getAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.getAnalytics();
    }
  }

  showDateFormat = () => {
    return this.props.showDateFormat
  }

  getAnalytics = () => {
    let dataArr = [];
      if(this.props.data.sessionDurationPerDate.result.error) {

      } else {
        if(this.props.data.sessionDurationPerDate.result?.reports[0]?.data?.rows) {
          this.props.data.sessionDurationPerDate.result.reports[0].data.rows.map((row, index) => {
            if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() === 0) {
              dataArr.push([
                row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : row.dimensions[0],
                Math.floor((Math.round(row.metrics[0].values[0]) / 60)),
                Math.floor((Math.round(row.metrics[0].values[1]) / 60))
              ]);
            } else if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() <= 31) {
              dataArr.push([
                row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('DD MMM'),
                Math.floor((Math.round(row.metrics[0].values[0]) / 60)),
                Math.floor((Math.round(row.metrics[0].values[1]) / 60))
              ]);
            } else if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() > 31) {
              dataArr.push([
                row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('MMM'),
                Math.floor((Math.round(row.metrics[0].values[0]) / 60)),
                Math.floor((Math.round(row.metrics[0].values[1]) / 60))
              ]);
            }
            console.log(row.metrics[0].values[0],
                row.metrics[0].values[1])
            this.setState({
              data: [['Time Period', 'Total visit time', 'Avg time per visit'], ...dataArr]
            })
          });
        } else{
          this.setState({
            data: [['Time Period', 'Total visit time', 'Avg time per visit'], [0.0, 0.0, 0.0]]
          })
        }
      }
  };

  render() {
    return(
      <Row>
        <Col lg={12} className="text-center pt-3">
          <h5 >Engagement</h5>
          {this.state.data.length > 0
            ? <Chart
                className='engagementDetails'
                style={{margin: '0 auto'}}
                chartType="AreaChart"
                loader={<div>Loading Chart</div>}
                data={this.state.data}
                options={{
                  hAxis: {
                    title: ` ${this.showDateFormat()} \n From ${moment(this.props.start_date).format('MM-DD-YYYY')} to ${moment(this.props.end_date).format('MM-DD-YYYY')}`,
                    titleTextStyle: { color: '#333', bold: true},
                    },
                  vAxis: { minValue: 0, title: this.state.vAxisTitle, titleTextStyle: {bold: true, fontSize: 14}},
                  chartArea: { width: '70%', height: '70%', bottom: '25%' },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
  }

}

export default XcubeAnalyticsDetailsEngagement;
