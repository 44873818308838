import React, { Component} from 'react';
import "./xcube-analytics-details-visitors-per-country.scss";
import {Row, Col} from 'react-bootstrap'
import XcubeChartjsGeo from "../xcube-chartjs-geo/xcube-chartjs-geo";

class XcubeAnalyticsDetailsVisitorsPerCountry extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    }
  };

  loadVisitorsPerCountry = () => {
    // console.log(this.props.data.usersPerCountry);
    if(!this.props.data.usersPerCountry.result.error){
      if(this.props.data.usersPerCountry?.result?.reports[0]?.data?.rows) {
        return this.props.data?.usersPerCountry?.result?.reports[0]?.data?.rows.map((row, index) =>
          <h6 style={{color: '#8c8c8c'}} key={index}>{row.dimensions[0]}: {row.metrics[0].values[0]}</h6>);
      }
    }
  };

  render() {
    return(
        <Row className='visitors_per_country'>
          <Col  lg={12} className="pt-3">
            <h5 className='mb-4'>Visitors per country</h5>
            {this.props.data ?
              <Row>
                <Col  md={4} lg={2} className="visitors d-none d-sm-block">
                  {this.loadVisitorsPerCountry()}
                </Col>
                <Col md={8} lg={10}>
                <XcubeChartjsGeo
                data={this.props.data}
                />
                </Col>
              </Row>
              : <p>No data to show yet</p>
            }
          </Col>
        </Row>
      )
  }
}

export default XcubeAnalyticsDetailsVisitorsPerCountry;
