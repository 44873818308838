import axios from 'axios';
import { authenticationService } from '../services/authenticationService';
import store from '../../redux/store';
import { displaySpinner, editRegistration } from '../../redux/actions';

export const interceptors = {
  reqInterceptor,
  respInterceptor,
};

function reqInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      const customConfig = config;
      store.dispatch(displaySpinner({value: true}));
      if (authenticationService.isUserAuth()) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        customConfig.headers.Authorization = `Bearer ${currentUser.Authorization}`;
        customConfig.headers['Content-Type'] = 'application/json';
      }
      return customConfig;
    }, (error) => {
      store.dispatch(displaySpinner({value: false}));
      return Promise.reject(error);
    }
  );
}

function respInterceptor() {
  axios.interceptors.response.use((response) => {
    store.dispatch(displaySpinner({value: false}));
    return response;
  }, (error) =>{
    store.dispatch(displaySpinner({value: false}));
    // authenticationService.logout();
    // window.location.reload();
    return Promise.reject(error);
  });

}
