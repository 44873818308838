import React, { useState } from 'react';
import { setDataService } from '../../services/setDataService';
import XcubeSweetAlert from '../xcube-sweetalert/xcube-sweetalert';
import { dataHelper } from '../../helpers/data-helper';
import previewImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaigns/assets/previewImg.svg';
import editImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/edit.svg';
import copyImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/copy.svg';
import removeImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/remove.svg';
import statsImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaigns/assets/statsImg.svg';

function XcubeRenderCampaigns(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [removeCampaignId, setRemoveCampaignId] = useState(false);

  function removeCampaign(id) {
    setShowAlert(true);
    setRemoveCampaignId(id);
  }

  const alertResponse = (resp) => {
    if(resp === "confirm"){
      setDataService.deleteCampaignData(removeCampaignId).then((resp) => {
        window.location.reload();
      });
    }else{
      setShowAlert(false);
    }
  }

  function copyCampaign(campaignId) {
    setDataService.copyCampaign(campaignId).then((resp) => {
      window.location.reload();
    });
  }

  function renderCampaigns(data) {

    const renderCampaigns = [];
    if (data.length > 0) {
      data.forEach((res) => {
        if(res.user){
          let preview_url = "https://editor.brame.ch/?id=" + res.id;
          renderCampaigns.push(
            <tr key={res.id}>
              <td>
                <div className="media">
                  <img
                    className="mr-3"
                    src={res.logo?.thumbnail_url ? res.logo?.thumbnail_url : res.logo ? res.logo : 'false'}
                    alt="Prize"
                  />
                  <div className="media-body">
                    <div>{res.name}</div>
                    <div>
                      <p>
                        Added on
                        {' '}
                        {dataHelper.formatDateHelper(res.createdAt, 'DD/MM/YYYY')}
                        {' '}
                        by
                        {` ${res.user.first_name} ${res.user.last_name}`}
                      </p>
                    </div>
                    <div className="btn-group options" role="group">
                      <a href={res.campaign_url} target={'_blank'}>
                        <img src={previewImg} alt=""/>
                        Preview
                      </a>
                      <a href={preview_url} target={'_blank'}>
                        <img src={editImg} alt=""/>
                        Edit
                      </a>
                      <a onClick={() => copyCampaign(res.id)} style={{ cursor: "pointer" }}>
                        <img src={copyImg} alt=""/>
                        Copy
                      </a>
                      <a onClick={() => removeCampaign(res.id)} style={{ cursor: "pointer" }}>
                        <img src={removeImg} alt=""/>
                        Delete
                        {' '}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
              <td><img src={res.game.thumbnail_url} alt=""/></td>
              <td>
                {res.analytics.visits}
              </td>
              <td>
                <div>
                  {res.analytics.conversions}%
                </div>
                <div className="options2">
                  <a href={`/campaigns/${res.id}/status`}>
                    <img src={statsImg} alt=""/>
                    Stats
                  </a>
                </div>
              </td>
              <td>
                <div>
                  {dataHelper.capitalizeString(res.status)} (
                  {dataHelper.daysLeftHelper(res.end_date)}
                  )
                </div>
                <div>
                  <p>
                    Ends on
                    {' '}
                    {dataHelper.formatDateHelper(res.end_date, 'DD/MM/YYYY')}
                  </p>
                </div>
                {/*<div className="options2">*/}
                {/*  <a href="/pause">*/}
                {/*    <img src={pauseImg} alt="" />*/}
                {/*    <span>Pause</span>*/}
                {/*  </a>*/}
                {/*</div>*/}
              </td>
            </tr>,
          );
        }
      });
    }
    return renderCampaigns;
  }
  return (
    <>
      <XcubeSweetAlert
        title="Are you sure?"
        confirmBtnText="Confirm"
        confirmBtnBsStyle="danger"
        type="danger"
        response={(resp) => alertResponse(resp)}
        show={showAlert}
        text="The Campaign will be permanently removed!"
      />
      {renderCampaigns(props.data)}
    </>
)


}
export default XcubeRenderCampaigns;
