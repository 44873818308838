import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import XcubeInput from '../../../../../../shared/components/xcube-input/xcube-input';
import './dashboard-account-general.scss';
import XcubeFilledButton
  from '../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeSelect from '../../../../../../shared/components/xcube-select/xcube-select';
import '../../../../../../shared/services/getDataService';
import { getDataService } from '../../../../../../shared/services/getDataService';
import { setDataService } from '../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';

class DashboardAccountGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.changeTitle('Account - General');
    this.getGeneralAccountData();
  };

  getGeneralAccountData = () => {
    getDataService.accountGeneralPage().then((resp) => {
      // console.log(resp);
      this.setState(
        resp.data.body
      );
    });
  };

  handleSubmitUser = () => {
    setDataService.updateUser(this.state).then((resp) => {
      // console.log(resp);
      localStorage.setItem('currentUser', JSON.stringify(resp.data.body));
      toast.success('Account information successfully updated!');
      window.location.reload();
    });
  };

  handleSubmitCompany = () => {
    setDataService.updateCompany(this.state.company).then((resp) => {
      // console.log(resp);
      toast.success('Account information successfully updated!');
    });
  };

  handleChange = (event, node) => {
    const value = event.value;
    const name = event.name;
    if(node) {
      this.setState(prevState => ({
        [node]: {
          ...prevState[node],
          [name]: value
        }
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  render = () => {
    return (
      <div id="dashboardAccountGeneral" className="pt-md-5">
        <div className="container">
          <Row>
            <div className="col-md-6 section-head">
              <h1>
                Basics
              </h1>
              <p>
                Set up the basic account information.
              </p>
            </div>
          </Row>
          <Row>
            <div className="col-md-3">
              <XcubeInput
                label="First Name"
                placeholder="John"
                id="first_name"
                required={true}
                type="text"
                name="first_name"
                onChange={this.handleChange}
                value={this.state.first_name}
              />
            </div>
            <div className="col-md-3">
              <XcubeInput
                label="Last Name"
                placeholder="Smith"
                id="last_name"
                required={true}
                type="text"
                name="last_name"
                onChange={this.handleChange}
                value={this.state.last_name}
              />
            </div>
            <div className="col-md-3">
              <XcubeInput
                label="E-mail"
                placeholder="johnsmith@company.com"
                id="email"
                required={true}
                type="email"
                name="email"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </div>
            <div className="col-md-3">
              <XcubeInput
                label="Password"
                placeholder="********"
                type="password"
                id="password"
                name="password"
                onChange={this.handleChange}
              />
            </div>
          </Row>
          <Row>
            <XcubeFilledButton
              text="Save changes"
              btnState="normal"
              onClick={() => this.handleSubmitUser()}
            />
          </Row>
          <Row>
            <div className="col-md-12">
              <hr />
            </div>
          </Row>
          <Row>
            <div className="col-md-6 section-head">
              <h1>
                Profile
              </h1>
              <p>
                Enter your full contact information here.
                This information can also be used to prefill certain sections and pages,
                such as the Connect section or Terms of Service.
              </p>
            </div>
          </Row>
          <Row>
            <div className="col-md-4">
              <XcubeInput
                label="Company"
                placeholder="Company, LLC."
                id="company"
                required={true}
                type="text"
                name="company"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.name}
              />
            </div>
            <div className="col-md-4">
              <XcubeInput
                label="Website"
                placeholder="company.com"
                id="website"
                required={true}
                type="text"
                name="website"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.website}
              />
            </div>
            <div className="col-md-4">
              <XcubeInput
                label="Contact e-mail"
                placeholder="johnsmith@company.com"
                id="contact_email"
                required={true}
                type="email"
                name="contact_email"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.contact_email}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-4">
              <XcubeInput
                label="Address"
                placeholder="24 Street"
                id="address"
                required={true}
                type="text"
                name="address"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.address}
              />
            </div>
            <div className="col-md-4">
              <XcubeInput
                label="City"
                placeholder="City Name"
                id="city"
                required={true}
                type="text"
                name="city"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.city}
              />
            </div>
            <div className="col-md-4">
              <XcubeInput
                label="Zip Code"
                placeholder="18000"
                id="zip_code"
                required={true}
                type="text"
                name="zip_code"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.zip_code}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-4">
              <XcubeSelect
                label="Country"
                options={[
                  {id: '1', name: 'United States'},
                  {id: '2', name: 'Switzerland'},
                  {id: '3', name: 'Germany'},
                ]}
                onChange={(event) => this.handleChange(event, 'company')}
                id={'country'}
                name={'country'}
                errorName={'country'}
                defaultValue={this.state.company?.country}
                required={true}
              />
            </div>
            <div className="col-md-4">
              <XcubeInput
                label="Phone"
                placeholder="+1-012-345-678"
                id="phone"
                required={true}
                type="number"
                name="phone"
                onChange={(event) => this.handleChange(event, 'company')}
                value={this.state.company?.phone}
              />
            </div>
          </Row>
          <Row>
            <XcubeFilledButton
              text="Save changes"
              btnState="normal"
              onClick={() => this.handleSubmitCompany()}
            />
          </Row>
          <Row>
            <div className="col-md-12">
              <hr />
            </div>
          </Row>
          <Row>
            <div className="col-md-9 section-head">
              <h1>
                Privacy & data
              </h1>
              <span>We value your trust and keep your data safe.</span>
              <p>
                Your data is kept safe on our platform’s data storage units in compliance with General Data Protection Regulation directives.
                Read our <a href="#">Terms & Conditions, Data Processing Agreement</a>  &  <a href="#">Privacy Policy</a> to find out more.
              </p>
            </div>
          </Row>
          {/*<Row>*/}
          {/*  <div className="col-md-6">*/}
          {/*    <XcubeCheckbox */}
          {/*      id="terms-of-use" */}
          {/*      title="I accept the Brame terms of use" */}
          {/*      label="Data supplier contract has been accepted on 10/03/2019 17:20 by John Smith." */}
          {/*    />*/}
          {/*  </div>*/}
          {/*</Row>*/}
        </div>
      </div>
    );
  }
}

export default DashboardAccountGeneral;
