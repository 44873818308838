import React, { Component} from 'react';
import "./xcube-analytics-devices-details.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";

class XcubeAnalyticsDevicesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.getAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.getAnalytics();
    }
  }

  getAnalytics = () => {
    let arr = [];
    if(!this.props.data.devices.result.error){
      if(this.props.data.devices.result.reports[0].data.rows) {
        this.props.data.devices.result.reports[0].data.rows.forEach((row) => {
          arr.push([
            row.dimensions[0][0].toUpperCase() + row.dimensions[0].slice(1) + " " + row.metrics[0].values[0], parseInt(row.metrics[0].values[0])
          ]);

        });

        this.setState({
         data: [['Device type', 'Number of devices'], ...arr]
        });
      }
    }

  };

  render() {
    return (
      <Row className='devicesUsed align-items-center pt-3'>
        <Col md={6} lg={12} className="text-center">
          {/*<h5 className='mt-3'>Devices</h5>*/}
          {this.state.data.length > 0
          ? <Chart
              className='deviceDetails'
              style={{margin: '0 auto'}}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={this.state.data}
              options={{
                chartArea: {top: '20'},
                slices: {
                  0: { color: '#9a84d8' },
                  1: { color: '#88c559' },
                  2: { color: '#f78b60'}
                },
                pieSliceText: 'value-and-percentage',
                legend: {
                  position: 'bottom',
                  alignment: 'center' ,
                  textStyle: {
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#8c8c8c',
                  fontName: 'Raleway'
                  }
                }
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsDevicesDetails;
