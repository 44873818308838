import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Table } from 'react-bootstrap';
import "./xcube-analytics-question-details.scss";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Chart from 'react-google-charts';

export default function XcubeAnalyticsQuestionDetails(props){
  console.log(props)

  return(
    <>
      <h5 className="text-center">
        Game Analytics
      </h5>
      <Accordion defaultActiveKey="0" id="xcube-analytics-question-details" className="mt-md-4">
        {
          props.questions.map((question, i) => {
            return (
              <Card key={i}>
                <Card.Header>
                  <Accordion.Toggle variant="link" eventKey={i} className="toggle-button">
                    {question.question}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i}>
                  <Card.Body>
                    <Row>
                      <Col md={12}>
                        <Table className="text-left" hover>
                          <thead style={{borderBottom: 'none'}} >
                          <tr>
                            <th>
                              Answers
                            </th>
                            <th>
                              Occurrences
                            </th>
                            <th>
                              Percentage
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            question.answers.map((answer, i) => {
                              return(
                                <tr key={i  + 'answers'}>
                                  <td>
                                    {answer.answer}
                                  </td>
                                  <td>
                                    {answer.occurrences}
                                  </td>
                                  <td>
                                    {answer.percentage}%
                                  </td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })
        }
      </Accordion>
    </>
  )
}
