import React, {Component} from 'react';
import "./campaign-navbar.scss";
import {
    Route,
    NavLink,
    Switch, useParams,
} from 'react-router-dom';
import CampaignStatus from "../campaign-status/campaign-status";
import CampaignGeneral from "../campaign-general/campaign-general";
import CampaignGame from "../campaign-game/campaign-game";
import CampaignPrizes from "../campaign-prizes/campaign-prizes";
import CampaignIntegrations from "../campaign-integrations/campaign-integrations";
import CampaignEmails from "../campaign-emails/campaign-emails";
import CampaignPublishing from "../campaign-publishing/campaign-publishing";
import Campaigns from "../campaigns/campaigns";
import { Nav, Dropdown } from "react-bootstrap";
import {Link} from 'react-router-dom'

class CampaignNavbar extends Component {

    // pageTitle = 'Campaigns';
    //
    // changePageTitle = (pageTitle) => {
    //     this.pageTitle = pageTitle;
    // };

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Campaigns',
            id: this.props.location.pathname.split('/')[2]
        };
    }

    changePageTitle = (pageTitle) => {
        this.setState({
            pageTitle: pageTitle
        });
    };

    render() {
        return (
            <div id="campaign-navbar">
                {/* <p className="page-title">{this.state.pageTitle}</p> */}

                <Nav className="dropdownLinks mb-2">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                {this.state.pageTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to={"/campaigns/"+ this.state.id +"/status"}>Status</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to={"/campaigns/"+ this.state.id +"/general"}>General</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to={"/campaigns/"+ this.state.id +"/prizes"}>Prizes</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to={"/campaigns/"+ this.state.id +"/emails"}>Emails</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to={"/campaigns/"+ this.state.id +"/publishing"}>Publishing</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>


                <ul className="stats nav nav-tabs justify-content-center">
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/status"}>Status</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id + "/general"}>General</NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/game-settings"}>Game settings</NavLink>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/prizes"}>Prizes</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/emails"}>Emails</NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/integrations"}>Integrations</NavLink>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to={"/campaigns/"+ this.state.id +"/publishing"}>Publishing</NavLink>
                    </li>
                </ul>
                <Switch>
                    <Route path="/campaigns" exact={true} render={(props) => <Campaigns {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/status" render={(props) => <CampaignStatus {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/general" render={(props) => <CampaignGeneral {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/game-settings" render={(props) => <CampaignGame {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/prizes" render={(props) => <CampaignPrizes {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/integrations" render={(props) => <CampaignIntegrations {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/emails" render={(props) => <CampaignEmails {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/campaigns/:id/publishing" render={(props) => <CampaignPublishing {...props} changeTitle={this.changePageTitle} />} />
                </Switch>
            </div>
        );
    }
}

export default CampaignNavbar;
